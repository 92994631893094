<template>
    <div>
        <Navbar :page="`Novo diagnóstico`" link="/diagnosticos" nameLink="Diagnósticos ativos" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="flex gap-1 bg-gray-100 p-2 rounded-md my-4">
                <div>
                    <svg 
                        class="h-10 w-10 fill-gray-500"
                        version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                        preserveAspectRatio="xMidYMid meet"
                    >

                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                     stroke="none">
                    <path d="M2504 5019 c-17 -19 -19 -41 -22 -183 -4 -184 6 -230 52 -242 45 -11
                    76 4 92 43 12 29 15 70 12 199 -3 186 -10 204 -78 204 -25 0 -43 -7 -56 -21z"/>
                    <path d="M1480 4758 c-28 -31 -25 -61 16 -142 77 -150 145 -257 172 -267 60
                    -23 115 38 93 103 -16 44 -148 277 -174 306 -27 29 -79 30 -107 0z"/>
                    <path d="M3533 4758 c-27 -29 -160 -263 -174 -306 -22 -66 33 -126 93 -103 39
                    15 208 309 208 363 0 61 -85 91 -127 46z"/>
                    <path d="M2388 4359 c-93 -13 -120 -23 -142 -52 -24 -33 -15 -70 24 -98 24
                    -17 30 -17 125 -3 122 19 332 14 450 -11 257 -54 484 -177 669 -361 175 -175
                    285 -368 354 -624 24 -88 26 -111 26 -305 0 -204 -1 -214 -32 -328 -36 -137
                    -115 -309 -194 -424 -29 -43 -111 -136 -181 -208 -177 -179 -251 -305 -275
                    -466 l-7 -54 -183 -3 -182 -2 0 345 c0 332 -1 346 -20 365 -30 30 -83 27 -109
                    -6 -20 -26 -21 -38 -21 -365 l0 -339 -105 0 -105 0 0 585 0 585 104 0 105 0 3
                    -146 c3 -139 4 -147 27 -165 30 -25 62 -24 95 2 26 20 26 21 26 164 l0 143 99
                    4 c88 3 104 7 147 34 134 82 172 251 84 373 -124 171 -390 131 -458 -68 -7
                    -19 -12 -69 -12 -112 l0 -79 -109 0 -108 0 -6 89 c-11 179 -102 274 -262 275
                    -151 1 -255 -104 -255 -257 0 -112 66 -205 170 -242 24 -8 77 -15 121 -15 l79
                    0 0 -585 0 -585 -178 0 -179 0 -17 83 c-33 153 -119 302 -243 417 -84 79 -168
                    175 -219 251 -355 534 -279 1235 182 1680 106 102 188 161 309 223 50 26 96
                    54 103 63 32 44 -3 113 -59 113 -34 0 -142 -52 -244 -118 -328 -211 -563 -561
                    -642 -959 -22 -111 -24 -402 -4 -513 60 -328 212 -604 474 -856 133 -129 193
                    -248 206 -411 8 -95 25 -123 78 -123 l33 0 0 -90 0 -90 -59 0 c-52 0 -62 -3
                    -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0 -80 0 -80 -59 0
                    c-52 0 -62 -3 -80 -26 -28 -35 -26 -69 4 -99 20 -21 34 -25 80 -25 l55 0 0
                    -32 c0 -45 40 -126 81 -167 39 -38 102 -70 139 -71 34 0 41 -7 69 -62 34 -67
                    113 -143 190 -181 62 -30 72 -32 176 -32 103 0 114 2 177 32 80 38 152 106
                    194 183 27 50 33 55 73 62 112 19 211 128 211 233 l0 35 54 0 c68 0 106 26
                    106 74 0 54 -20 70 -92 76 l-63 5 -3 78 -3 77 54 0 c69 0 107 26 107 74 0 54
                    -20 70 -92 76 l-63 5 0 85 0 85 38 6 c48 8 66 32 67 89 1 75 28 181 66 259 30
                    61 62 101 178 221 241 248 362 463 428 755 25 115 36 388 19 507 -67 468 -355
                    878 -772 1098 -161 84 -321 136 -492 159 -90 13 -305 13 -389 0z m-124 -1411
                    c41 -18 59 -56 64 -135 l5 -73 -78 0 c-74 0 -78 1 -111 34 -27 27 -34 42 -34
                    74 0 82 80 134 154 100z m752 -7 c42 -26 60 -78 44 -127 -18 -52 -59 -74 -142
                    -74 l-68 0 0 74 c0 60 4 78 21 100 38 49 93 58 145 27z m74 -1761 l0 -90 -505
                    0 -505 0 0 90 0 90 505 0 505 0 0 -90z m0 -320 l0 -80 -505 0 -505 0 0 80 0
                    80 505 0 505 0 0 -80z m0 -251 c0 -27 -27 -72 -54 -90 -17 -11 -95 -15 -411
                    -17 -214 -2 -409 0 -433 3 -53 8 -100 48 -108 93 l-6 32 506 0 506 0 0 -21z
                    m-295 -283 c-18 -27 -77 -70 -118 -85 -87 -33 -218 0 -286 73 l-35 36 227 0
                    227 0 -15 -24z"/>
                    <path d="M755 3975 c-32 -31 -33 -75 -2 -102 45 -41 288 -173 318 -173 59 0
                    91 73 52 118 -26 29 -295 182 -322 182 -12 0 -33 -11 -46 -25z"/>
                    <path d="M4150 3915 c-79 -46 -150 -92 -157 -101 -19 -26 -16 -71 7 -94 35
                    -35 71 -25 212 56 157 90 178 107 178 146 0 35 -40 78 -72 78 -13 0 -88 -38
                    -168 -85z"/>
                    <path d="M490 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0 187
                    0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205 -20z"/>
                    <path d="M4220 2950 c-25 -25 -26 -71 -1 -101 19 -24 22 -24 206 -24 184 0
                    187 0 206 24 25 30 24 76 -1 101 -19 19 -33 20 -205 20 -172 0 -186 -1 -205
                    -20z"/>
                    <path d="M934 2028 c-78 -46 -149 -89 -158 -97 -22 -19 -21 -83 2 -104 35 -32
                    75 -20 214 59 142 82 178 111 178 148 0 33 -29 65 -65 71 -25 5 -55 -9 -171
                    -77z"/>
                    <path d="M3990 2097 c-13 -7 -29 -25 -35 -40 -17 -49 9 -75 160 -164 150 -87
                    196 -100 228 -65 22 25 22 85 0 104 -29 25 -298 178 -314 178 -8 0 -25 -6 -39
                    -13z"/>
                    </g>
                    </svg>
                </div>
                <div class="text-gray-500 flex flex-col text-sm text-justify">
                    <span class="font-bold">
                    Orientação rápida
                    </span>
                    <span>
                        O diagnóstico selecionado será enviado apenas através do botão "enviar", localizado nas
                        opções da tela inicial do módulo. Antes disso, é necessário escolher os destinatários e clicar
                        em salvar. Para obter mais informações, assista ao nosso tutorial clicando no ícone no canto
                        superior direito da tela inicial do módulo
                    </span>
                </div>
                </div>
            <form action="#" method="POST">
                <div class="grid grid-cols-12 gap-4">
                    
                    <div class="flex flex-col col-span-12 md:col-span-5 gap-2">

                        <div>
                            <label for="diagnosticoBase" class="block text-sm font-medium">Diagnóstico base</label>
                            <select 
                                v-model="form.diagnosticoBase" 
                                name="diagnosticoBase" 
                                id="diagnosticoBase" 
                                class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                            >
                                <option :value="null" default>
                                   Selecione um diagnóstico
                                </option>
                                <option 
                                    v-for="(item, index) in diagnosticosBase" :key="index"
                                    :value="item._id">
                                    {{  item.tema }}
                                </option>
                            </select>
                        </div>

                        <div>
                            <label for="diagnosticoBase" class="block text-sm font-medium">
                                Tipo do diagnóstico
                            </label>
                            <select 
                                v-model="form.tipoDiagnostico" 
                                name="diagnosticoBase" 
                                id="diagnosticoBase" 
                                class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                            >
                                <option value="interno">Interno</option>
                                <option value="externo">Externo</option>
                            </select>
                        </div>

                        <div>
                            <label for="buscaParmsTipo" class="block text-sm font-medium">Tipo de destinatário</label>
                            <select v-model="buscaParms.tipo" name="buscaParmsTipo" id="buscaParmsTipo" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                <option value="Pessoa na Empresa">Pessoa na Empresa</option>
                                <option value="Agente de Tratamento">Agente de Tratamento</option>
                            </select>
                        </div>

                        <div v-if="buscaParms.tipo !== 'Outro'" class="grid grid-cols-12 gap-2 mt-2">
                            <div class="col-span-12 md:col-span-9">
                                <label class="block text-sm font-medium">
                                    {{ buscaParms.tipo === 'Pessoa na Empresa' ? 'Setor' : 'Tipo Agente de tratamento' }}
                                </label>
                                <select v-if="buscaParms.tipo === 'Pessoa na Empresa'" v-model="buscaParms.setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option default :value="null">Todos os setores</option>
                                    <option v-for="s in setores" :key="s._id" :value="s._id">{{ s.nome }}</option>
                                </select>
                                <select v-if="buscaParms.tipo === 'Agente de Tratamento'" v-model="buscaParms.agente" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                                    <option default :value="null">Todos os tipos</option>
                                    <option v-for="agenteTratamento in agentesTratamento" :key="agenteTratamento._id" :value="agenteTratamento._id">{{ agenteTratamento.nome }}</option>
                                </select>
                            </div>
                            <div class="col-span-12 md:col-span-3">
                                <label class="block text-sm font-medium">Buscar</label>
                                <button @click="startBusca()" type="button" class="inline-flex justify-center w-full py-2 px-2 mt-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <div v-if="buscaParms.tipo !== 'Outro'" class="mt-2">
                            <span class="font-semibold text-sm">Total: {{total}} <span @click="selecionarDestinatarios()" class="text-green-700 underline cursor-pointer ml-1">Selecionar todos</span> 
                            <span class="text-blue-700 ml-1">Selecionados: {{selecionadosBusca.length}}</span></span>
                        </div>
                        <button v-if="total > 0 && buscaParms.tipo !== 'Outro'" @click="addConvidados()" type="button" class="inline-flex justify-center py-1 px-2 mt-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-700">
                            Adicionar destinatários
                        </button>
                        <div v-if="busca.length && buscaParms.tipo !== 'Outro'" class="h-96 overflow-auto px-3 py-2 bg-gray-200 rounded-lg mt-2">
                            <label v-for="item in busca" :key="item._id" class="block text-sm font-medium my-1">
                                <input type="checkbox" v-model="item.check" @change="changeBusca(item)" class="rounded-sm" :id="item._id" v-if="!item.adicionado">
                                <input type="checkbox" v-model="sempreMarcado" class="rounded-sm text-gray-400" :id="item._id" disabled v-else> 
                                <span :class="`ml-2 ${item.adicionado ? 'text-gray-500': ''}`">
                                    {{ item.nome }}<span v-if="item.email"> - {{ item.email }} ({{ item.setor ? item.setor.nome : 'Sem setor' }} / {{ item.funcao ? item.funcao.nome : 'Sem Função' }})</span>
                                </span>
                            </label>
                        </div>
                       
                    </div>
        
                    <div class="col-span-12 md:col-span-7">
                        <div class="col-span-12 md:col-span-7 mb-1">
                            <label for="conteudoMsg" class="block text-sm font-medium">
                                Personalize a comunicação via e-mail
                            </label>
                            <textarea 
                                type="text" 
                                rows="5" 
                                v-model="form.conteudoMsg" 
                                name="conteudoMsg" 
                                id="conteudoMsg" 
                                class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                            >
                            </textarea>
                        </div>
                        <h3 class="text-sm font-medium">Lista de destinatários para envios</h3>
                        <span class="font-semibold text-green-600 text-sm">
                            Total: {{this.convidados.length}} 
                        </span>
                        <span 
                            @click="removerConvidados" 
                            v-if="selecionadosConvidados.length" 
                            class="text-sm font-semibold text-red-700 underline cursor-pointer ml-1">
                            Remover selecionados
                        </span> 
                        <span v-if="selecionadosConvidados.length" class="text-sm font-semibold text-blue-700 ml-1">
                            Selecionados: {{selecionadosConvidados.length}}
                        </span>
                        <div v-if="convidados.length" class="h-120 overflow-auto mt-2">
                            <div class="py-2 align-middle inline-block min-w-full">
                                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <thead class="bg-gray-50 text-xs ">
                                            <tr>
                                                <th scope="col" class="px-1 py-2 text-left text-gray-500 tracking-wider cursor-pointer">
                                                    <svg @click="selecionarTodosConvidados()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                </th>
                                                <th scope="col" class="px-3 py-2 font-medium text-left text-gray-500 tracking-wider">
                                                    Destinatário
                                                </th>
                                                <th scope="col" class="px-3 py-2 font-medium text-left text-gray-500 tracking-wider">
                                                    E-mail
                                                </th>
                                                <th scope="col" class="px-3 py-2 font-medium text-left text-gray-500 tracking-wider">
                                                    Status
                                                </th>
                                                <th scope="col" class="px-3 py-2 font-medium text-left text-gray-500 tracking-wider">
                                                    Confirmação de recebimento
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(item, index) in convidados" :key="index">
                                                <td v-if="!$store.state.user.cliente" class="px-1 py-2">
                                                    <div class="flex items-center">
                                                        <input 
                                                        :checked="selecionadosConvidados.map(el => el.id).indexOf(item.id) > -1" @change="(e) => selecionadosControl(item, e)" 
                                                        type="checkbox" 
                                                        class="rounded-sm"
                                                        >
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.nome ? item.nome : ''}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{item.email ? item.email : ''}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{ item.status }}{{ item.data_envio && item.status === 'Enviado' ? ` em ${formatDate(item.data_envio)}` : '' }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-3 py-2">
                                                    <div class="flex items-center">
                                                        <div class="text-sm text-gray-500">
                                                            {{  formatDate(item.recebimento_confirmado) || '-' }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <h4 v-else class="text-base">Nenhum destinatário</h4>
                    </div>
                </div>
                <div class="px-5 pb-4">
                </div>
            </form>
            

            <div class="flex justify-end gap-2 mt-14">
                <button @click="$router.push({ path: route})" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
export default {
    data() {
        return {
            route: '/diagnosticos',
            idx: 0,
            sempreMarcado: true,
            outro: { nome: "", email: "" },
            diagnosticosBase: [],
            selecionarTodos: false,
            form: {
                diagnosticoBase: null,
                empresa: '',
                destinatariosAgente: [],
                destinatariosPessoas: [],
                conteudoMsg: '',
                quantidadeDestinatarios: 0,
                vinculado_pai: false,
                tipoDiagnostico: 'interno',
            },
            listaAgentes: [],
            listaPessoas: [],
            convidados: [],
            selecionadosConvidados: [],
            setores: [],
            agentesTratamento: [],
            busca: [],
            selecionadosBusca: [],
            total: 0,
            buscaParms: {
                tipo: "Pessoa na Empresa",
                setor: null,
                agente: null,
            },
        }
    },
    methods: {
        formatDate(data) {
            if(!data) return '';
            return `${moment(data).format("DD/MM/YYYY")}`;
        },
        async save() {
            
            const diagnosticoBaseSelecionado = this.diagnosticosBase.find((item)=> item._id === this.form.diagnosticoBase)
            if(diagnosticoBaseSelecionado){
                this.form.vinculado_pai = diagnosticoBaseSelecionado.incluirPAI
            }else{
                return this.$vToastify.warning('Selecione diagnóstico base.');
            }
           
            this.form.quantidadeDestinatarios = this.convidados.length
            
            this.form.destinatariosPessoas = []
            this.form.destinatariosAgente = []
            
            this.convidados.forEach(convidado => {
                const { id } = convidado;

                console.log(convidado)

                const agenteEncontrado = this.listaAgentes.find(agente => agente._id === id);
                const pessoaEncontrada = this.listaPessoas.find(pessoa => pessoa._id === id);

                if (agenteEncontrado) {
                    this.form.destinatariosAgente.push({
                        destinatario: id,
                        status: convidado.status,
                        data_envio: convidado.data_envio,
                        recebimento_confirmado: null,
                    });
                }else if(pessoaEncontrada){
                    this.form.destinatariosPessoas.push({
                        destinatario: id,
                        setor: pessoaEncontrada?.setor?._id,
                        status: convidado.status,
                        data_envio: convidado.data_envio,
                        recebimento_confirmado: null,
                    });
                }
            });

            const method = this.form._id ? 'put' : 'post';
            await this.$http[method](`/v1/diagnosticoControle`, this.form)
            .then((res)=>{
                this.$vToastify.success(res.data?.msg);
                this.$router.push({path: this.route});
            })
            .catch((e)=>this.$vToastify.error(e.response?.data?.err))
        },
        async update(file, response) {
            this.form.arte = response.file;
        },
        async removerConvidados(){

            this.$confirm({
                title: 'Confirmação',
                message: `Tem certeza que deseja remover esses destinatários? Se eles já foram enviados o diagnóstico aplicado a eles será removido junto.`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if (confirm) {
                        this.convidados = this.convidados.filter(convidado => 
                            !this.selecionadosConvidados.some(selecionadoConvidado => convidado.id === selecionadoConvidado.id)
                        );

                        this.selecionadosConvidados = [];
                        this.selecionadosBusca = [];
                        this.desmarcarOpcoes();
                        this.marcarAdicionados();
                    }
                }
            });
        },
        selecionadosControl(item, e){
            if (this.selecionadosConvidados.map(el => el.id).indexOf(item.id) > -1 && !e.target.checked) {
                this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el.id).indexOf(item.id), 1);
            } else {
                this.selecionadosConvidados.push(item);
            }
        },
        selecionarTodosConvidados(){
            for(let i = 0; i < this.convidados.length; i++){
                const item = this.convidados[i];
                if(this.selecionadosConvidados.map(el => el.id).indexOf(item.id) ==! -1) this.selecionadosConvidados.splice(this.selecionadosConvidados.map(el => el.id).indexOf(item.id), 1);
                else this.selecionadosConvidados.push(item);
            }
        },
        async addConvidados(){
           
            this.selecionadosBusca.forEach((selecionado) => {
                if (!this.convidados.some(convidado => convidado.id === selecionado.id)) {
                    this.convidados.push(selecionado);
                }
            });

            this.selecionadosBusca = [];
            
            this.desmarcarOpcoes();
            this.marcarAdicionados();

        },
        changeBusca(item){
            if(item.check) {
                this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
            }else {
                const index = this.selecionadosBusca.map(el => el.id).indexOf(item._id);
                if(index !== -1){
                    this.selecionadosBusca.splice(index, 1);
                }
            }
        },
        selecionarDestinatarios() {
            this.selecionarTodos = !this.selecionarTodos

            if (!this.selecionarTodos) {
                this.desmarcarOpcoes();
                this.selecionadosBusca = [];
                for(let i = 0; i < this.busca.length; i++){
                    const item = this.busca[i];
                    if(item.adicionado) this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                }
            }

            if (this.selecionarTodos) {
                for(let i = 0; i < this.busca.length; i++){
                    const item = this.busca[i];
                    const existe = this.selecionadosBusca.some(selecionado => selecionado.id === item._id);

                    if (!existe) {
                        item.check = true;
                        this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                    }
                }
            }
        },
        desmarcarOpcoes(){
            this.busca.forEach((item)=>{
                item.check =false;
            });
        },
        marcarAdicionados() {
            for (let i = 0; i < this.busca.length; i++) {
                const item = this.busca[i];
                const indiceAdicionado = this.convidados.findIndex((convidado) => convidado.id === item._id);

                if (indiceAdicionado !== -1) {
                    this.busca[i].adicionado = true
                    this.selecionadosBusca.push({ id: item._id, nome: item.nome, email: item.email });
                }
                else this.busca[i].adicionado = false;
            }
        },
        async pegarPossiveisDestinatarios(){
            const reqPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true });
            this.listaPessoas = reqPessoas.data.data;

            const reqFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true });
            this.listaAgentes = reqFornecedores.data.data;
        },
        async startBusca(){
            if(this.selecionadosBusca.length > 0) {
                return this.$vToastify.error("Adicione os selecionados aos convidados, antes de realizar uma nova busca!");
            }

            if(this.buscaParms.tipo === 'Pessoa na Empresa') {
                const reqbusca = await this.$http.post(`/v1/pessoas/list`, { all: true, setor: this.buscaParms.setor });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            if(this.buscaParms.tipo === 'Agente de Tratamento') {
                const reqbusca = await this.$http.post(`/v1/fornecedores/list`, { all: true, agente: this.buscaParms.agente });
                this.busca = reqbusca.data.data;
                this.total = reqbusca.data.total;
            }
            this.marcarAdicionados();
        },

    },
    async beforeMount() {

        const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
        this.form.empresa = this.$store.state.empresa._id

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;

        const listAgentesTratamento = await this.$http.post(`/v1/agentes/list`, { all: true});
        this.agentesTratamento = listAgentesTratamento.data.data;
        
        this.pegarPossiveisDestinatarios();

        await this.$http.post(`/v1/diagnosticosBase/list`)
        .then((resp)=> this.diagnosticosBase = resp.data.itens)

        this.startBusca();

        if (id) {
            await this.$http.get(`/v1/diagnosticoControle/${id}`)
            .then((resp)=> {
                Object.assign(this.form, resp.data)
                console.log(this.form)

                const agentes = (this.form.destinatariosAgente || []).map(item => ({
                    id: item.destinatario?._id,
                    nome: item.destinatario?.nome,
                    email: item.destinatario?.email || '',
                    status: item.status || '',
                    recebimento_confirmado: item.recebimento_confirmado || '',
                    data_envio: item.data_envio,
                    diagAplicado: item.diagAplicado,
                }));

                const pessoas = (this.form.destinatariosPessoas || []).map(item => ({
                    id: item.destinatario?._id,
                    nome: item.destinatario?.nome,
                    email: item.destinatario?.email || '',
                    status: item.status || '',
                    recebimento_confirmado: item.recebimento_confirmado || '',
                    data_envio: item.data_envio,
                    diagAplicado: item.diagAplicado,
                }));
                    
                this.convidados = [...agentes, ...pessoas];
            })
            .catch(()=>{
                this.$vToastify.error('Erro ao obter diagnóstico')
                this.$router.push({path: this.route});
                
            });
        }
    },
}
</script>
